import React from 'react';
import {isMobile} from "react-device-detect";
import {googleScriptLoadingDelay} from "../../utils/articleUtil";
import '../../css/components/_share-toolbox.scss'
import { addTimeout } from '../../utils/timeManager';

class Share extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: null
    }
  }

  componentDidMount() {
    this.setState({url: window.location.href});
    addTimeout(() => {
      const headElement = document.getElementsByTagName("head")[0];
      const script = document.createElement("script");
      script.async = true;
      script.defer = true;
      script.src = `https://platform-api.sharethis.com/js/sharethis.js#property=64462ab6cbd2bb001b85f748&product=sop`;
      headElement.append(script);
      try {
        //window.addthis.layers.refresh();
        window.__sharethis__.initialize();
      } catch (e) {
        console.log(e);
      }
    }, googleScriptLoadingDelay() + (isMobile ? 8000 : 5000));
  }

  render() {
    return <div className="sharethis-inline-share-buttons" data-url={this.state.url}></div>;
  }
}

export default Share;
